<template lang="pug">
  div(:id="graphId")
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "EvolutionMainNumberOpinion",
  props: ['chartData', 'label'],
  data: () => ({
    type: 'line',
    myChart: null,
  }),
  computed: {
    graphId() {
      return `evolutionMainNumberOpinionGraph_${this.label}`
    },
    options() {
      return {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {}
        },
      }
    }
  }
  ,
  watch: {
    chartData: {
      handler(newData) {
        if (newData) {
          this.renderChart();
        }
      }
      ,
      immediate: true
    }
  }
  ,
  methods: {
    renderChart() {
      const chartDom = document.getElementById(this.graphId);
      if (!this.myChart) {
        this.myChart = echarts.init(chartDom);
      }
      console.log({value: {...this.options, ...this.chartData}})
      this.myChart.setOption({...this.options, ...this.chartData});
    }
  }
  ,
  mounted() {
    if (this.chartData) {
      this.renderChart();
    }
  }
}
</script>
