<template lang="pug">
  .categories.mb-2(v-if="tags && tags.length > 0")
    span.boxCategory(v-for="(tag, index) in tags" :key="index")
      span.category(:title="tag.tag.question" :class="getClassTagExternalByNote(tag.rating)") {{ tag.rating }} {{ tag.tag.name || tag.tag.code }}
</template>

<script>
export default {
  props: ['tags', 'site', 'siteInfo'],
  methods: {
    getClassTagExternalByNote(note) {
      /*
      Verde: cuando van sobre 5, las del 5 y cuando va sobre 10 es del 8.1 al 10
      Naranja: cuando van sobre 5, 4 Y cuando va sobre 10 es del 6.1 al 8
      Rojo: cuando van sobre 5, 1,2 y 3 y cuando va sobre 10 es del 0 al 6
      */
      let fieldBase = this.site.code === 'CMN' ? this.siteInfo['cover_rating_base'] : this.site['base']
      if (!note) return 'cat-BrandWhite'
      if (fieldBase === 10) {
        if (note >= 8.1) return 'catExternalGreen'
        if (note >= 6.1 && note < 8.1) return 'catExternalOrange'
        if (note < 6.1) return 'catExternalRed'
      }
      if (fieldBase === 5) {
        if (note === 5) return 'catExternalGreen'
        if (note === 4) return 'catExternalOrange'
        if (note < 4) return 'catExternalRed'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.categories {
  .category {
    background-color: #eaeaea;
    border-radius: 15px;
    padding: 2px 10px;
    font-size: 0.75rem;
    color: #666;
    margin-right: 7px;
    margin-bottom: 5px;
    display: inline-block;

    &.cat-BrandWhite {
      background-color: #58585e;
      color: #fff;
    }

    &.catExternalGreen {
      color: #fff;
      background-color: #89C445;
    }

    &.catExternalOrange {
      color: #fff;
      background-color: #FCAE19;
    }

    &.catExternalRed {
      color: #fff;
      background-color: #e95d4b;
    }
  }
}
</style>
