<script>
import Chart from 'chart.js'
import {generateChart, mixins} from 'vue-chartjs'

const {reactiveProp} = mixins

// CUSTOMIZAMOS LA GRAFICA DE LINEA PARA AÑADIR BARRAS DE COLORES A LOS LATERALES
Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.defaults.global.legend.labels.usePointStyle = true;

const CustomLine = generateChart('custom-line2', 'LineWithLine')
export default {
  name: "valid_name",
  extends: CustomLine,
  mixins: [reactiveProp],
  props: ['chartData', 'legend'],
  data: () => ({
    chartDataCustom: null,
    options: {
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return data['datasets'][tooltipItem[0]['datasetIndex']]['label'];
          },
          label: function (tooltipItem, data) {
            return 'Negativas: ' + data['datasets'][tooltipItem['datasetIndex']]['dataTotal'][tooltipItem['index']]['count_negative'];
          },
        },
        backgroundColor: '#FFF',
        titleFontSize: 16,
        titleFontColor: '#4A4A4A',
        bodyFontColor: '#000',
        bodyFontSize: 14,
        displayColors: false
      },
      legend: {
        display: true,
        position: 'right',
        align: 'start',
      },
      plugins: {
        datalabels: {
          display: false
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            stepSize: 1,
            suggestedMin: -100,
            suggestedMax: 100,
          },
          gridLines: {
            tickMarkLength: 50
          },
        }, {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            tickMarkLength: 50
          },
          ticks: {
            stepSize: 1,
            suggestedMin: -100,
            suggestedMax: 100,
          }
        }],
      },
    }
  }),
  watch: {
    chartDataCustom() {
      this.renderChart(this.chartData, this.options)
    }
  },
  created() {
    this.chartDataCustom = this.chartData
  },
  mounted() {
    // MOSTRAMOS VALORES DEL EJE Y A IZQUIERDA Y DERECHA

    Chart.plugins.register({
      beforeInit: function (chart) {
        if (chart.canvas.id == 'custom-line2') {
          Chart.controllers.LineWithLine = Chart.controllers.line.extend({
            draw: function (ease) {
              Chart.controllers.bubble.prototype.draw.call(this, ease);
              var ctx = this.chart.chart.ctx;
              // LINEA VERDE IZQUIERDA
              ctx.beginPath();
              ctx.moveTo(40, 6);
              ctx.lineWidth = 11;
              ctx.strokeStyle = '#89C445';
              ctx.lineTo(40, 370);
              ctx.stroke();
              ctx.save();

              // LINEA NARANJA IZQUIERDA
              ctx.beginPath();
              ctx.moveTo(40, 150);
              ctx.lineWidth = 11;
              ctx.strokeStyle = '#FCAE19';
              ctx.lineTo(40, 220);
              ctx.stroke();
              ctx.save();

              // LINEA ROJA IZQUIERDA
              ctx.beginPath();
              ctx.moveTo(40, 220);
              ctx.lineWidth = 11;
              ctx.strokeStyle = '#F16621';
              ctx.lineTo(40, 370);
              ctx.stroke();
              ctx.save();

              ctx.restore();
            }

          })
          if (chart.config.data.datasets.length <= 1) {
            chart.config.options.legend.display = false
          }
        }
      }
    });
    this.renderChart(this.chartData, this.options)
  }
}
</script>
