// Importamos mutaciones
import * as types from '@/store/mutation-types'

const reports = {
  state: {
    reports: [],
    reportTypesSelected: [],
    isLoading: false
  },
  mutations: {
    [types.REPORTS](state, data) {
      state.reports = data
    },
    [types.REPORT_FILTER_TYPES_SELECTED](state, data) {
      state.reportTypesSelected = data
    },
    [types.REPORTS_IS_LOADING](state, data) {
      state.isLoading = data
    },
  },
  getters: {
    'getReports': state => {
      return state.reports
    },
    'getReportTypesSelected': state => {
      return state.reportTypesSelected
    },
    'getReportLoading': state => state.isLoading
  }
}

export default reports
