<template lang="pug">
  div.pa-4
    div.filters
      div.filter.filtroDate
        range-date

    v-card.pa-4.d-flex.flex-col
      v-col(cols="12")
        v-row.pb-4.pt-2.px-1
          span.text-16.text-semibold #[translate Ingresa la palabra por la que quieres filtrar el informe]
        v-row.px-4.justify-space-between(align="center")
          div
            input(type="text" v-model="newKeyword" :disabled="keywords.length === keyWordLimit" placeholder="Añadir palabra clave" maxlength="54" class="rounded input")
            v-btn(color="primary" @click="addKeyword" :disabled="keywords.length === keyWordLimit" class="ml-2")
              | #[translate Añadir palabra]
          div.d-flex.align-center(v-if="keywords.length")
            span.text-16.text-semibold.pr-2 #[translate Keyword]:
            v-chip-group
              v-chip.chip(variant="outlined" v-for="keyword in keywords" :key="keyword" close @click:close="removeKeyword(keyword)")
                span {{ keyword }}

      v-row.py-8.px-4
        span.text-16.text-semibold #[translate Tipo de opiniones]
      v-col.justify-center.align-center.d-flex.stars-container.flex-col(cols="12")
        v-row.justify-center.items-center.stars-container.d-flex.w-full.justify-space-around(style="max-width: 75%")
          v-checkbox(v-model="ratings" label="" value="5")
            template(v-slot:label)
              stars-combinations(:bigger="true" :combination="['5']")
          v-checkbox(v-model="ratings" label="" value="4")
            template(v-slot:label)
              stars-combinations(:bigger="true" :combination="['4']")
          v-checkbox(v-model="ratings" label="" value="3")
            template(v-slot:label)
              stars-combinations(:bigger="true" :combination="['3']")
          v-checkbox(v-model="ratings" label="" value="2")
            template(v-slot:label)
              stars-combinations(:bigger="true" :combination="['2']")
          v-checkbox(v-model="ratings" label="" value="1")
            template(v-slot:label)
              stars-combinations(:bigger="true" :combination="['1']")

      v-col.d-flex.justify-center.align-center.pt-6(cols="12")
        v-btn(color="primary" @click="downloadReport" :disabled="!validBody" class="ml-2" :loading="$store.getters.getReportLoading")
          | #[translate Generar informe]


</template>

<script>
import commonMixins from "@/mixins/common.js"
import RangeDate from "@/components/filters/RangeDates.vue";
import OneSelectTree from "@/components/filters/OneSelectTree.vue";
import StarsCombinations from "@/components/feedback/StarsCombinations.vue";
import * as types from "@/store/mutation-types";
import SrvReports from "@/services/reports";

export default {
  mixins: [commonMixins],
  components: {RangeDate, OneSelectTree, StarsCombinations},
  data() {
    return {
      keyWordLimit: 1,
      newKeyword: '',
      keywords: [],
      ratings: [],
    }
  },
  mounted() {
    if (window.sessionStorage.getItem("excel_by_word_keywords")) {
      this.keywords = JSON.parse(window.sessionStorage.getItem("excel_by_word_keywords"));
    }
    if (window.sessionStorage.getItem("ratings")) {
      this.ratings = JSON.parse(window.sessionStorage.getItem("ratings"));
    }
  },
  computed: {
    validBody() {
      return this.keywords.length > 0 && this.ratings.length > 0
    }
  },
  watch: {
    ratings() {
      window.sessionStorage.setItem("ratings", JSON.stringify(this.ratings))
    },
    keywords() {
      window.sessionStorage.setItem("excel_by_word_keywords", JSON.stringify(this.keywords))
    }
  },
  methods: {
    async downloadReport() {
      this.$store.commit(types.DOWNLOADING_EXCEL, true)
      this.$store.commit(types.REPORTS_IS_LOADING, true)
      SrvReports.getReportExcelByWordDownload(this.keywords[0], this.ratings)
        .then((res) => {
          if (res.status === 200) {
            var blob = new Blob([res.data], {
              type: res.headers["content-type"],
            })
            const link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)

            let center = document.querySelector('[data-id="' + res.node + '"][data-type="' + res.type + '"]')
            let elementName = center.getAttribute('data-name')
            let name = elementName.replaceAll(' ', '-')
            link.download = `report_${name}_by_${this.keywords[0]}.pdf`
            this.$store.commit(types.DOWNLOADING_EXCEL, false)
            link.click()
          }
        }).catch(error => {
        console.error("[ERROR: downloadReport]:", error)
      }).finally(() => {
        this.$store.commit(types.REPORTS_IS_LOADING, false)
        this.$store.commit(types.DOWNLOADING_EXCEL, false)
      })
    },
    addKeyword() {
      if (this.newKeyword && this.keywords.length < this.keyWordLimit) {
        this.keywords.push(this.newKeyword)
        this.newKeyword = ''
      }
    },
    removeKeyword(keyword) {
      this.keywords = this.keywords.filter(k => k !== keyword)
    },
  }
}
</script>

<style lang="scss" scoped>
.stars-container {
  background: #E2E3E9;
  border-radius: 12px;
}
</style>
