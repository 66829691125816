<template lang="pug">
  div
    div.margin-bottom-0.gridFull
      v-row.headTitleGrid
        v-col(md="4")
          .block-count.pl-1
            span.mr-1.font-weight-med {{countEmployees}}
            translate Employees
        v-col(md="8")
          div.filters
            span.downloadExcel(@click="downloadExcelCustom()")
              img(:src="imgCommons.download" width="10")
            span.labelFilter #[translate Filter reviews]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="opinionsTypes" label-class="text-capitalize-first-letter" :itemsChecked="opinionsTypesSelectedMaped" @checked="onCheckOpinionsTypes" :min-width="150" id-key="value" label-key="text")

            span.labelFilter #[translate Categories]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="opinionsCategories" label-class="text-capitalize-first-letter" :itemsChecked="opinionsCategoriesSelected" @checked="onCheckOpinionsCategories" id-key="id" label-key="name")
      v-row.mt-0#headerEmployees
        .row.text-left
          v-col.col-12.tablaGR
            .container
              .row
                div(:class="{'col-4': typeNode === 'center', 'col-2': typeNode !== 'center'}")
                  span # #[translate Employee]
                  .boxOrder
                    <sort :orderBy="'name'" :orderByActual="orderingEmployees" @sort="setOrderEmployees($event)" />
                .col-2(v-if="typeNode !== 'center'")
                  span #[translate Restaurant]
                  .boxOrder
                    <sort :orderBy="'reviews__center'" :orderByActual="orderingEmployees" @sort="setOrderEmployees($event)" />
                .col-2
                  span #[translate No. of opinions]
                  .boxOrder
                    <sort :orderBy="'n_reviews'" :orderByActual="orderingEmployees" @sort="setOrderEmployees($event)" />
                .col-2
                  span #[translate N.º Opiniones Positivas]
                  .boxOrder
                    <sort :orderBy="'count_positives'" :orderByActual="orderingEmployees" @sort="setOrderEmployees($event)" />
                .col-2
                  span #[translate N.º Opiniones neutras]
                  .boxOrder
                    <sort :orderBy="'count_neutrals'" :orderByActual="orderingEmployees" @sort="setOrderEmployees($event)" />
                .col-2
                  span #[translate N.º opiniones negativas]
                  .boxOrder
                    <sort :orderBy="'count_negatives'" :orderByActual="orderingEmployees" @sort="setOrderEmployees($event)" />
      v-row.contentOutPadding.mt-0#boxTableComments
        .card-content
        .row.text-left.mt-0
          v-col.col-12.tablaGR.pt-0.pb-0(v-if="data")
            .container
              v-expansion-panels(v-model="panelActive")
                .row(v-for="(employe, index) in data" :ref="`elementRef_${index}`")
                  v-expansion-panel(
                    :key="index" @click="getOpinionsEmploye(employe,null,index)")
                    v-expansion-panel-header
                      .nameEmploye(:class="{'col-4': typeNode === 'center', 'col-2': typeNode !== 'center'}")
                        span.posEmployee {{index + 1}}
                        span {{employe.name}}
                      .col-2.nameEmploye(v-if="typeNode !== 'center'")
                        span {{ employe && employe.center ? employe.center.name : ''}}
                      .col-2
                        .boxNumberReviews
                          span {{employe.reviews}}
                      .col-2
                        .boxNumberReviews
                          span.cPositive {{employe.positives}}
                      .col-2
                        .boxNumberReviews
                          span.cNeutralDark {{employe.neutrals}}
                      .col-2
                        .boxNumberReviews
                          span.cNegative {{employe.negatives}}
                    v-expansion-panel-content
                      table-opinions(:employedId="employe.id" :total-pages="totalCommentsPages" :employeCenterId="employe.center.id" :comments="commentsLoad" :loading="loading"  @linkFilterCategory="linkFilterByCategory" @setOrder="setOrder" :pagination="true" @handlePageChange="handlePageChange")

</template>

<script>
import SrvFeedback from '@/services/feedback.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import FilterTreeMenu from "@/components/filters/filterTreeMenu"
import TableOpinions from "@/components/global/TableOpinions"
import Sort from "@/components/common/Sort.vue"

export default {
  mixins: [commonMixins],
  components: {FilterTreeMenu, TableOpinions, Sort}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  data() {
    return {
      loading: true, // BOOLEANA PARA MOSTRAR LOADING EN LA TABLE
      opinionsTypes: [ // ELEMENTOS FILTRO POR TIPO DE COMENTARIO
        {value: 'with_text', text: this.$gettext('With comments')},
        {value: 'positives', text: this.$gettext('Positives')},
        {value: 'neutrals', text: this.$gettext('Neutral')},
        {value: 'negatives', text: this.$gettext('Negatives')},
        {value: 'with_reply', text: this.$gettext('With reply')},
        {value: 'without_reply', text: this.$gettext('Without reply')}
      ],
      panelActive: [],
      ordering: '-source_date',
      orderingEmployees: '-n_reviews',
      opinionsTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
      commentsLoad: [],
      totalCommentsPages: null,
      employeActive: null,
      data: null,
      img: {
        sort: require('@/assets/img/mini_arrow_shack_disable.svg'),
        sortActive: require('@/assets/img/mini_arrow_shack_active.svg')
      },
      next: "",
      previous: "",
      scrollOffset: 0,
      lastIndexActive: 0,
    }
  },
  // OBTENERMOS DEL STORE LOS FILTROS APLICADOS
  async created() {
    this.opinionsSitesSelected = this.$store.getters.getOpinionsSitesSelected
    if (this.$store.getters.getNode > 0) {
      this.getEmployees()
    }
  },
  watch: {
    filterApply: function () {
      this.getEmployees()
    },
    ordering: function () {
      if (this.employeActive) {
        this.getOpinionsEmploye(this.employeActive)
      }
    },
    orderingEmployees: function () {
      this.commentsLoad = []
      this.employeActive = null
      this.panelActive = []
      this.getEmployees()
    },
  },
  computed: {
    countEmployees() {
      if (this.data) {
        return this.data.length
      }
      return 0
    },
    opinionsCategories() {
      let categoriesCrud = []
      let selectedCat = this.$store.getters.getTypesSelected ? this.$store.getters.getTypesSelected : 'internet'

      if (this.$store.getters.getCategories[selectedCat]) {
        categoriesCrud = this.$store.getters.getCategories[selectedCat].categories
        categoriesCrud = categoriesCrud.map((c) => {
          // BORRAMOS SUBCATEGORIAS PARA PANTALLA KEYWORDS
          c.children = []
          return c
        })
      }
      return categoriesCrud
    },
    opinionsCategoriesSelected() {
      return this.$store.getters.getOpinionsCategoriesSelected.map(c => c.value)
    },
    opinionsTypesSelectedMaped() {
      return this.opinionsTypesSelected.map(c => c.value)
    }
  },
  methods: {
    handlePageChange(type) {
      window.scrollTo({
        top: this.scrollOffset,
        behavior: 'smooth'
      });
      if (type === "previous" && this.previous) return this.getOpinionsEmploye(this.employeActive, this.previous)
      if (type === "next" && this.next) return this.getOpinionsEmploye(this.employeActive, this.next)
    },
    setOrder(val) {
      this.ordering = val
    },
    linkFilterByCategory(category) {
      this.onCheckOpinionsCategories([category])
    },
    downloadExcelCustom() {
      let val = this.$store.getters.getOpinionsCategoriesSelected
      let type = ''
      if (val.length > 0) {
        type = val[0].type
      }
      let params = {type: type}
      this.downloadExcel(params)
    },
    // FUNIONES LLAMADAS DESDE EL COMPONENTE MULTISELECT CON EMMIT
    onCheckOpinionsTypes(val) {
      this.$store.commit(types.FEEDBACK_FILTER_OPINIONS_TYPES_SELECTED, val)
      this.getEmployees()
      if (this.employeActive) {
        this.getOpinionsEmploye(this.employeActive)
      }
    },
    onCheckOpinionsCategories(val) {
      this.$store.commit(types.FEEDBACK_FILTER_OPINIONS_CATEGORIES_SELECTED, val)
      this.getEmployees()
      if (this.employeActive) {
        this.getOpinionsEmploye(this.employeActive)
      }
    },
    getEmployees() {
      SrvFeedback.getEmployees(this.orderingEmployees)
        .then(res => {
          if (res.status === 200) {
            this.data = res.data
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    // LLAMAMOS AL SERVICIO QUE CARGA LOS COMENTARIOS CON LOS FILTROS APLICADOS
    getOpinionsEmploye: function (employe, url, index) {
      this.loading = true
      this.employeActive = employe
      const request = url ? SrvFeedback.getNextReviewsForEmploye(url) : SrvFeedback.getReviewsForEmploye(employe.id, employe.center.id, this.ordering)

      request
        .then(res => {
          if (res.status === 200) {
            let custom = res.data.results.map((obj, index) => ({
              ...obj,
              ['comentariocompleto']: false,
              ['showReply']: false,
              ['showByPlugin']: false,
              ['unsavedReply']: '',
              ['idGenerate']: obj.id + '-' + index
            }))
            if (this.mocking) {
              if (!custom[0].reply) {
                custom[0].reply = {}
              }
              custom[0].reply.text = ""
              custom[0].link_to_publication_img = "https://media-cdn.tripadvisor.com/media/photo-s/14/c7/5e/e4/img-20180924-130725-largejpg.jpg"
              custom[0].allow_reply = true
            }
            this.next = res?.data?.next ?? ''
            this.previous = res?.data?.previous ?? ''
            this.totalCommentsPages = res?.data?.count
            this.commentsLoad = custom
            this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
            this.loading = false

            const [currentRef] = this.$refs[`elementRef_${index}`]
            this.scrollOffset = window.scrollY + currentRef.getBoundingClientRect().top - 180
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.boxOrder {
  margin-top: 4px;
  display: inline-block;
  margin-left: 15px;

  .btnOrder {
    display: block;
    line-height: 0;
    cursor: pointer;

  }
}

.v-expansion-panels {
  display: block;

  .row {
    margin-bottom: 0;
    margin-top: 0;
    border-bottom: 2px solid #ccc;

  }
}

.v-expansion-panel {
  &:hover {
    background-color: #F2F2F2;
  }

  &:before {
    box-shadow: none;
  }
}

.theme--light.v-expansion-panels .v-expansion-panel {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;

}

.container {
  max-width: 100%;
  width: 100%;
}

.v-expansion-panel-header {
  min-height: 0;
  padding: 0;
}

#headerEmployees {
  padding: 0 1px;
  font-size: 0.75rem;

  .container {
    background-color: #fbfbfb;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }
}

.nameEmploye {
  border-right: 1px solid #ccc;
  color: #43425D;
  font-size: 0.875rem;
  font-weight: 600;
}

.posEmployee {
  display: inline-block;
  min-width: 30px;
  font-weight: 400;
}

.boxNumberReviews {
  min-width: 80px;
  text-align: right;
  display: inline-block;
}
</style>
