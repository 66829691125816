<template lang="pug">
  div
    success-state(v-if="showSuccess" :visible="showSuccess" @close="showSuccess=false" @confirmed="showSuccess=false")
    error-state(v-else-if="showError" :visible="showError" @close="showError=false" @confirmed="showError=false")
    v-dialog.dialogEdit(v-else v-model="show"
      width="680")
      v-card.dialogEdit
        v-card-text.pb-0
        v-container
          div.gr-loader-snipper--wrapper(v-if="loading")
            span.gr-loader-snipper
          v-row#headerModalEdit.pt-3
            v-col(cols="10")
              span(v-if="isEditionMode") #[translate Editar un evento]
              span(v-else) #[translate Añadir un evento]
            v-col.mt-5(cols="2")
              img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
          v-row#bodyModalEdit
            v-col(cols="12")
              <Errors v-if="errors" :errors="errors" />
            v-col(cols="6")
              v-container
                v-row

                  v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                    div.messageError
                      span {{ $gettext('An error occurred while recording.') }}
                  v-col.text-left.pt-6.pb-0(cols="12")
                    span #[translate Título del evento ]
                    span.text-red *
                    input.inputFullEditModal.rounded(type="text" v-model="data.eventName" maxlength="54")
                    span.text-12.text-red(v-if="errorEventName") Campo obligatorio
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Fecha de Inicio ]
                    span.text-red *
                    <div class="d-flex sss">
                    <DatePicker v-model="data.startDate" @change="controlDates" class="w-full flex-1 mr-2 max-w-auto rounded" :disabled-date="disabledBeforeTodayAndAfterAWeek" :open.sync="showDateInit"></DatePicker>
                    <label @click="showDateInit = true">
                    <img :src="imgCalendar" class="icoCalendar" />
                    </label>
                    </div>
                    span.text-12.text-red(v-if="errorStartDate") Campo obligatorio
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Hora de Inicio]
                    input.inputFullEditModal.rounded(type="time" v-model="data.startTime")
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Fecha de Fin ]
                    span.text-red *
                    <div class="d-flex">
                    <DatePicker v-model="data.endDate" class="w-full flex-1 mr-2 max-w-auto rounded" id="end" :disabled-date="disabledBeforeStartDate" :open.sync="showDateEnd"></DatePicker>
                    <label @click="showDateEnd = true">
                    <img :src="imgCalendar" class="icoCalendar"  />
                    </label>
                    </div>
                    span.text-12.text-red(v-if="errorEndDate") Campo obligatorio
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Hora de finalización]
                    input.inputFullEditModal.rounded(type="time" v-model="data.endTime")
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Información del evento]
                    textarea.textareaFullEditModal.rounded(v-model="data.summary")
                  v-col.text-left.pb-0(cols="12" class="d-flex items-center")
                    span.mr-2 #[translate Añadir botón]
                    select.selectFullEditModal.rounded(v-model="data.actionType" class="flex-1")
                      option(v-for="(boton, index) in buttons" :value="boton.name") {{ boton.name_es }}
                  v-col.text-left.pb-0(cols="12" :class="{'opacity-50': disabledUrlButton}")
                    span #[translate Enlace de tu botón]
                    input.inputFullEditModal.rounded(type="url" :disabled="disabledUrlButton" v-model="data.actionTypeUrl")
                  v-col.text-left.pb-0(cols="12" v-if="isDemoFacebook && !data.createTime")
                    p.mb-0 Publicar tambien en:
                    v-row
                      v-col.d-flex.items-center(cols="12")
                        input.mr-1(type="checkbox" v-model="data.to_facebook")
                        img.mr-4(src="@/assets/img/local/FCB.svg" width="25")
                        input.mr-1(type="checkbox" v-model="data.to_instagram")
                        img(src="@/assets/img/local/INS.svg" width="25")

            v-col(cols="6")
              <addPhotos class="mt-7" @removeImage="remove()" @loadImage="local_image = $event" :image="photo" :maxSize="5000000"></addPhotos>
              <span class="mt-8 d-block">Programar esta publicación:</span>
              <div class="d-flex">
              <DatePicker v-model="data.dateProgramation" class="w-full flex-1 mr-1 max-w-auto rounded" id="dateProgramation" :disabled-date="disabledAfterStartDate" :open.sync="showDateProgramation"></DatePicker>
              <label @click="showDateProgramation = true">
              <img :src="imgCalendar" class="icoCalendar"  />
              </label>
              <input type="time" class="inputFullEditModal rounded w-auto ml-3" v-model="data.timeProgramation" />
              </div>
          v-row#footerModalEdit
            v-col(cols="12")
              button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
              button.btnSaveEditModal(v-if="idsBussinesModeMultiple" @click="showConfirm=true")
                span(v-if="isEditionMode") #[translate Guardar cambios]
                span(v-else) #[translate Publicar]
              button.btnSaveEditModal(v-else @click="validate")
                span(v-if="isEditionMode") #[translate Guardar cambios]
                span(v-else) #[translate Publicar]
              button.btnDeleteEditModal(v-if="isEditionMode" @click="showDeleteModal=true")
                span #[translate Cancelar programación]

    modal-confirm-multiple-edit(:visible="showConfirm" @close="showConfirm=false" @confirmed="validate()")
    modal-confirm-delete(:visible="showDeleteModal" @close="showDeleteModal=false" @confirmed="deleteEvent(data.id)")
</template>

<script>
import commonMixins from '@/mixins/common.js'
import SrvLocalPosts from '@/services/localPosts.js'
import ModalConfirmMultipleEdit from '@/components/local/confirmMultipleEdit.vue'
import ModalConfirmDelete from "@/components/local/confirmDelete.vue"
import Errors from '@/components/common/Errors.vue'
import DatePicker from 'vue2-datepicker';
import addPhotos from '@/components/local/posts/addPhotos.vue'
import SuccessState from "@/components/local/successState.vue"
import ErrorState from "@/components/local/errorState.vue"
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'

export default {
  mixins: [commonMixins],
  props: ['visible', 'idBussines', 'post', 'idsBussinesModeMultiple', 'editionData'],
  components: {DatePicker, addPhotos, ModalConfirmMultipleEdit, Errors, ModalConfirmDelete, SuccessState, ErrorState},
  data() {
    return {
      loading: false,
      showConfirm: false,
      showDeleteModal: false,
      showSuccess: false,
      showError: false,
      imgClose: require('@/assets/img/ico-close-edit.svg'),
      imgCalendar: require('@/assets/img/local/calendar.svg'),
      name: '',
      showDateInit: false,
      showDateEnd: false,
      showDateProgramation: false,
      buttons: [],
      data: {
        topicType: 'EVENT', summar: null, startTime: null, endTime: null, startDate: null, endDate: null, local_image: null, eventName: null, actionType: "NONE", actionTypeUrl: null,
        startDay: null, startMonth: null, startYear: null,
        endDay: null, endMonth: null, endYear: null,
        startHour: null, startMinute: null, endHour: null, endMinute: null, to_facebook: false, to_instagram: false, dateProgramation: null, timeProgramation: null
      },
      local_image: null,
      section: 'details-restaurant',
      saveStatus: true,
      errors: null,
      errorEventName: false,
      errorStartDate: false,
      errorEndDate: false,
      errorMsg: '',
      disableKeys: ["CALL", "NONE"]
    }
  },
  watch: {
    editionData() {
      this.data = {...this.data, ...this.editionData}
      if (this?.editionData?.local_image) this.local_image = this.editionData.local_image
    },
    visible(newValue) {
      if (newValue) {
        this.showError = false
        this.showSuccess = false
        if (!this.isEditionMode) {
          if (this.post) {
            let postData = Object.assign({}, this.post)
            this.data.eventName = postData.event.title
            this.data.startDate = this.$moment(postData.event.schedule.startDate.year + '-' + postData.event.schedule.startDate.month + '-' + postData.event.schedule.startDate.day).toDate()
            this.data.endDate = this.$moment(postData.event.schedule.endDate.year + '-' + postData.event.schedule.endDate.month + '-' + postData.event.schedule.endDate.day).toDate()
            this.data.startDay = parseInt(postData.event.schedule.startDate.day)
            this.data.startMonth = parseInt(postData.event.schedule.startDate.month)
            this.data.startYear = postData.event.schedule.startDate.year
            this.data.endDay = parseInt(postData.event.schedule.endDate.day)
            this.data.endMonth = parseInt(postData.event.schedule.endDate.month)
            this.data.endYear = postData.event.schedule.endDate.year
            this.data.name = postData.name
            this.data.summary = postData.summary
            this.data.actionType = postData.actionType
            this.data.actionTypeUrl = postData.actionTypeUrl
            this.local_image = postData.media.length > 0 ? postData.media[0].googleUrl : null
            this.data.actionType = postData.callToAction ? postData.callToAction.actionType : null
            this.data.actionTypeUrl = postData.callToAction ? postData.callToAction.url : null
            this.data.dateProgramation = null
            this.data.timeProgramation = null
          } else {
            this.data = {
              topicType: 'EVENT', summar: null, startTime: null, endTime: null, startDate: null, endDate: null, local_image: null, eventName: null, actionType: "NONE", actionTypeUrl: null,
              startDay: null, startMonth: null, startYear: null,
              endDay: null, endMonth: null, endYear: null,
              startHour: null, startMinute: null, endHour: null, endMinute: null
            }
          }
        }
        this.loadData()
      }
    },
    eventName(newVal) {
      if (newVal) {
        this.errorEventName = false
      }
    },
    startDate(newVal) {
      if (newVal) {
        this.errorStartDate = false
      }
    },
    endDate(newVal) {
      if (newVal) {
        this.errorEndDate = false
      }
    },
    actionType(newVal) {
      if (newVal === "NONE") {
        this.actionTypeUrl = null
      }
    }
  },
  computed: {
    disabledUrlButton() {
      return !this.data.actionType || this.disableKeys.includes(this.data.actionType)
    },
    photo() {
      return this.local_image
    },
    isEditionMode() {
      return this.editionData
    },
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    eventName() {
      return this.data.eventName
    },
    startDate() {
      return this.data.startDate
    },
    endDate() {
      return this.data.endDate
    }
  },
  methods: {
    emitSuccessEvent() {
      this.$emit("handleSuccess")
    },
    controlDates() {
      if (this.data.startDate > this.data.endDate) {
        this.data.endDate = null
      }
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    disabledBeforeStartDate(date) {
      const today = new Date(this.$moment(this.data.startDate).add(1, 'days'));
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    disabledAfterStartDate(date) {
      const today = new Date(this.$moment(this.data.startDate).add(1, 'days'));
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    async loadData() {
      try {
        const res = await SrvLocalPosts.getButtonActions()
        if (res.status === 200) {
          this.buttons = res.data.filter(e => e.name !== 'ACTION_TYPE_UNSPECIFIED')
          this.buttons = [...this.buttons, {
            id: "none",
            name: "NONE",
            name_es: "Ninguno"
          }]
        }
      } catch (error) {
        // console.error("[LOAD_DATA_ADD_EVENT_ERROR]: ", error)
      }
    },
    async deleteEvent(id) {
      try {
        this.loading = true;
        await SrvLocalPosts.deleteScheduledPost(id)
        this.emitSuccessEvent();
        this.show = false;
        this.showSuccess = true;
      } catch (error) {
        this.showError = true;
      } finally {
        this.loading = false;
      }
    },
    validate() {
      let error = false
      if (!this.data.eventName) {
        this.errorEventName = true
        error = true
      } else {
        this.errorEventName = false
      }

      if (!this.data.startDate) {
        this.errorStartDate = true
        error = true
      } else {
        this.errorStartDate = false
      }

      if (!this.data.endDate) {
        this.errorEndDate = true
        error = true
      } else {
        this.errorEndDate = false
      }

      if (!error) {
        this.save()
      }
    },
    remove() {
      delete this.data.media
      this.local_image = null
    },
    async save() {
      if (!this.loading) {
        this.loading = true
        const form = new FormData()
        form.append('topicType', 'EVENT')
        if (this.local_image && !this.data.media) {
          form.append('local_image', this.local_image)
        }

        if (this.data.summary) {
          form.append('summary', this.data.summary)
        }
        if (this.data.eventName) {
          form.append('eventName', this.data.eventName)
        }
        if (this.data.name) {
          form.append('name', this.data.name)
        }
        if (this.data.actionType && this.data.actionType != "NONE") {
          form.append('actionType', this.data.actionType)
          form.append('actionTypeUrl', this.data.actionTypeUrl)
        }

        if (this.data.startDate) {
          let partsStart = this.$moment(this.data.startDate).format('DD/MM/YYYY').split('/')
          form.append('startDay', parseInt(partsStart[0]))
          form.append('startMonth', parseInt(partsStart[1]))
          form.append('startYear', partsStart[2])
        }
        if (this.data.endDate) {
          let partsEnd = this.$moment(this.data.endDate).format('DD/MM/YYYY').split('/')
          form.append('endDay', parseInt(partsEnd[0]))
          form.append('endMonth', parseInt(partsEnd[1]))
          form.append('endYear', partsEnd[2])
        }
        if (this.data.startTime) {
          let [hour, minutes] = this.data.startTime.split(':')
          form.append('startHour', parseInt(hour))
          form.append('startMinute', parseInt(minutes))
        }

        if (this.data.endTime) {
          let [hour, minutes] = this.data.endTime.split(':')
          form.append('endHour', parseInt(hour))
          form.append('endMinute', parseInt(minutes))
        }

        let valid = true
        if (this.data.actionType && !this.disableKeys.includes(this.data.actionType) && !this.data.actionTypeUrl) {
          valid = false
          this.errors = 'El campo de enlace es obligatorio'
        }
        // Si es una creación simple de un post para un centro
        if (!this.idsBussinesModeMultiple && !this.data.dateProgramation && !this.data.timeProgramation) {
          if (valid) {
            const res = await SrvLocalPosts.setPost(this.idBussines, form, this.data.to_facebook, (this.local_image || this.data.media) && this.data.to_instagram ? true : false)
            if (res.status === 200) {
              this.$emit('saved', {data: res.data, 'action': 'update'})
              this.$emit('close')
            }
            if (res.status === 201) {
              this.$emit('saved', {data: res.data, 'action': 'create'})
              this.$emit('close')
            }
          }
        }

        if ((this.data.dateProgramation && !this.data.timeProgramation) || (!this.data.dateProgramation && this.data.timeProgramation)) {
          valid = false
          this.errors = 'Si desea programar esta publicación debe completar el campo de fecha y de hora'
        }

        // si vienen del listado para crear un post para varios centros
        if (this.idsBussinesModeMultiple || (this.data.dateProgramation && this.data.timeProgramation)) {

          let formMultiple = new FormData()
          let objClean = {}
          Object.entries(this.data).forEach((e) => {
            if (e[1] && e[0] !== 'startDate' && e[0] !== 'endDate') {
              if (e[0] !== "actionType" || e[1] !== "NONE") {
                objClean[e[0]] = e[1]
              }
            }
            if (e[0] == 'startDate') {
              let partsStart = this.$moment(this.data.startDate).format('DD/MM/YYYY').split('/')
              objClean['startDay'] = parseInt(partsStart[0])
              objClean['startMonth'] = parseInt(partsStart[1])
              objClean['startYear'] = partsStart[2]
            }
            if (e[0] == 'endDate') {
              let partsEnd = this.$moment(this.data.endDate).format('DD/MM/YYYY').split('/')
              objClean['endDay'] = parseInt(partsEnd[0])
              objClean['endMonth'] = parseInt(partsEnd[1])
              objClean['endYear'] = partsEnd[2]
            }
            if (e[0] == 'startTime' && this.data.startTime) {
              let [hour, minutes] = this.data.startTime.split(':')
              objClean['startHour'] = parseInt(hour)
              objClean['startMinute'] = parseInt(minutes)
            }
            if (e[0] == 'endTime' && this.data.endTime) {
              let [hour, minutes] = this.data.endTime.split(':')
              objClean['endHour'] = parseInt(hour)
              objClean['endMinute'] = parseInt(minutes)
            }
          })

          delete objClean.local_image
          delete objClean.startTime
          delete objClean.endTime
          delete objClean.timeProgramation
          delete objClean.dateProgramation
          let centers = this.idsBussinesModeMultiple

          if (!this.idsBussinesModeMultiple && this.data.dateProgramation && this.data.timeProgramation) {
            centers = [this.idBussines]
          }

          if (this.data.dateProgramation && this.data.timeProgramation) {
            const [hours, minutes] = this.data.timeProgramation.split(':')
            formMultiple.append('scheduled_datetime', this.$moment(this.data.dateProgramation).set({hour: hours, minute: minutes}).utc().format("YYYY-MM-DD-HH:mm:ss"))
          }

          formMultiple.append('post_details', JSON.stringify({"center_ids": centers, "update_type": "POST", "post_details": objClean}))
          if (this.local_image) {
            formMultiple.append('image', this.local_image);
          }
          formMultiple.append('update_type', 'POST');

          if (valid) {
            try {
              const res = this.isEditionMode ? await SrvLocalPosts.updateScheduledPost(formMultiple, this.data.id) : await SrvLocalPosts.setPostMultiple(formMultiple)
              if (res.status === 200) {
                this.show = false
                this.showSuccess = true
                this.emitSuccessEvent()
              }
            } catch (error) {
              this.showError = true;
            }
          }
        }
        this.loading = false
      }

    }
  }
}
</script>
<style lang="scss" scoped>
.boxDeliveryService {
  background-color: #F2F2F2;
  border-radius: 4px;
  width: 100%;
  margin: 6px;

  &.noService {
    .urlDeliveryService {
      color: #CECECE;
    }
  }

  img {
    vertical-align: middle;
  }
}

.long {
  height: 136px;
}

.messageError {
  background-color: #F5B1B8;
  border: 1px solid #F93549;
  color: #F93549;
  padding: 15px;
}

#bodyModalEdit {
  .inputFullEditModal {
    &.textDisabled {
      background-color: #f8f8f8;
      color: #CECECE;
    }
  }
}

.icoCalendar {
  height: 36px;
}
</style>
