import { render, staticRenderFns } from "./TableOpinions.vue?vue&type=template&id=625357c2&scoped=true&lang=pug"
import script from "./TableOpinions.vue?vue&type=script&lang=js"
export * from "./TableOpinions.vue?vue&type=script&lang=js"
import style0 from "./TableOpinions.vue?vue&type=style&index=0&id=625357c2&prod&lang=scss&scoped=true"
import style1 from "./TableOpinions.vue?vue&type=style&index=1&id=625357c2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625357c2",
  null
  
)

export default component.exports