// Stores
import Stores from "@/store/index";
// importamos los tipos de mutación de vuex
import * as types from "@/store/mutation-types";
// Config
import appConfig from "@/config-app.js";
// funciones comunes
import SrvCommon from "@/services/common";

// Axios
import axios from "axios";

axios.defaults.baseURL = appConfig.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  getReports() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/reports/`;

      let token = SrvCommon.getToken();
      query += `?tree=${Stores.getters.getTreeSelected.id}`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      if (Stores.getters.getReportTypesSelected.length > 0) {
        for (let i = 0; i < Stores.getters.getReportTypesSelected.length; i++) {
          query += `&period=${Stores.getters.getReportTypesSelected[i].value}`;
        }
      }
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getReportsNext(url) {
    return new Promise((resolve, reject) => {
      let token = SrvCommon.getToken();

      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(url)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getReportsTypes() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/report_periods/`;

      let token = SrvCommon.getToken();
      query += `?tree=${Stores.getters.getTreeSelected.id}`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getReportDownload(startDate, endDate, period, obj) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/reports/pdf/download/`;

      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      // DESCOMENTAR AL TERMINAR PRUEBAS
      query += `?tree=${Stores.getters.getTreeSelected.id}`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      if (obj?.sites) {
        obj.sites.forEach((site) => {
          query += `&site=${site}`;
        });
      }

      if (obj?.sections) {
        obj.sections.forEach((section) => {
          query += `&sections=${section}`;
        });
      }

      if (obj?.delivery) {
        query += `&delivery=true`;
      }

      if (obj?.internet) {
        query += `&internet=true`;
      }

      query += `&before=${endDate}&after=${startDate}&period=${period}`;

      axios
        .get(query, {responseType: "blob"})
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },

  //REPORTS EN EXCEL
  getReportsExcel() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/excelreports/`;

      let token = SrvCommon.getToken();
      query += `?tree=${Stores.getters.getTreeSelected.id}`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      if (Stores.getters.getReportTypesSelected.length > 0) {
        for (let i = 0; i < Stores.getters.getReportTypesSelected.length; i++) {
          query += `&period=${Stores.getters.getReportTypesSelected[i].value}`;
        }
      }

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getReportsExcelRestalia() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/excelreportreviews/`;

      let token = SrvCommon.getToken();
      query += `?tree=${Stores.getters.getTreeSelected.id}`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      if (Stores.getters.getReportTypesSelected.length > 0) {
        for (let i = 0; i < Stores.getters.getReportTypesSelected.length; i++) {
          query += `&period=${Stores.getters.getReportTypesSelected[i].value}`;
        }
      }

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getReportExcelDownload(id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/reports/excel/download/`;

      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      // DESCOMENTAR AL TERMINAR PRUEBAS
      query += `?tree=${Stores.getters.getTreeSelected.id}`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      // BORRAR AL TERMINAR PRUEBAS
      //query += '?tree=1&node=299'

      query += `&report=${id}`;

      axios
        .get(query, {responseType: "blob"})
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getReportExcelByWordDownload(word, ratings) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/reports/reviews-term/download/`;

      let token = SrvCommon.getToken();
      let type = "N"

      axios.defaults.headers.common["Authorization"] = "Token " + token;
      query += `?tree=${Stores.getters.getTreeSelected.id}`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
          type = "C"
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      if (word) query += `&term=${word}`;
      if (ratings.length > 0) {
        ratings.forEach(rating => {
          query += `&rating=${rating}`;
        })
      }

      query += `&before=${Stores.getters.getDateBefore}&after=${Stores.getters.getDateAfter}`;

      axios
        .get(query, {responseType: "blob"})
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve({...res, node: Stores.getters.getNode, type});
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getReportExcelRestaliaDownload(id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/reports/excelreviews/download/`;

      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      // DESCOMENTAR AL TERMINAR PRUEBAS
      query += `?tree=${Stores.getters.getTreeSelected.id}`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      // BORRAR AL TERMINAR PRUEBAS
      //query += '?tree=1&node=299'

      query += `&report=${id}`;

      axios
        .get(query, {responseType: "blob"})
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
};
